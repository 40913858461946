import ShopPage from './features/shop';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from './store';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import useABTests from '@hooks/useABTests';

const queryClient = new QueryClient();

export type ShopRootProps = {
  pageDesign: any;
};

const ShopRoot = (props: ShopRootProps) => {
  useABTests();

  useEffect(() => {
    rootEl.removeAttribute('data-page-design');
  }, []);

  const { pageDesign } = props;

  return (
    <Router basename='/'>
      <ShopPage pageDesign={JSON.parse(pageDesign)} />
    </Router>
  );
};

const rootEl = document.getElementById('shop-module');

const shouldRenderReact = true; // !!root && (REACT_URLS as string[]).includes(window.location.pathname);

if (shouldRenderReact) {
  createRoot(rootEl).render(
    <ErrorBoundary>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ShopRoot {...(rootEl.dataset as any)} />
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
}
